<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="rect"></div>

    <v-container>
      <v-row
        class="showcase showcase-hero py-8 py-md-0"
        justify="center"
        align="center"
      >
        <v-col cols="12" md="6" class="showcase-text">
          <h1 class="showcase-header">
            {{ $t("home.justLetPeopleKnow.label") }}
          </h1>
          <p class="mb-md-16 mb-8">
            {{ $t("home.justLetPeopleKnow.content") }}
          </p>
          <v-btn class="btn-main mt-md-10 w-180 h-57">{{
            $t("tabs.download")
          }}</v-btn>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="showcase-phone mt-11 mt-lg-4 pb-8 pb-md-0"
        >
          <img
            src="../../assets/img/Main-front.png"
            alt="Google Pixel 5"
            class="showcase-img showcase-img-1"
          />
          <img
            src="../../assets/img/Main-back.png"
            alt="Google Pixel 5"
            class="showcase-img showcase-img-2"
          />
        </v-col>
      </v-row>
    </v-container>

    <div class="about">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="4">
            <h2 class="about-header mb-1">
              {{ $t("home.whatIsFeedy.label") }}
            </h2>
            <p class="about-para">
              {{ $t("home.whatIsFeedy.content") }}
            </p>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="6">
            <v-row justify="end">
              <v-img
                max-height="428"
                max-width="640"
                width="100%"
                height="100%"
                src="../../assets/img/feed.svg"
                alt="Feed"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="slider">
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="slider-rect slider-rect--1"
      ></div>
      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="slider-rect slider-rect--2"
      ></div>
      <v-container>
        <v-row class="slider-showcase" justify="center" align="center">
          <div>
            <h1 class="slider-header mb-10 mb-md-12">
              {{ $t("home.whyFeedy") }}
            </h1>
            <swiper ref="swiper" class="swiper" :options="swiperOptions">
              <swiper-slide
                class="swiper-slide"
                v-for="(slider, index) in sliders"
                :key="index"
              >
                <div class="swiper-box">
                  <img class="mb-3" :src="slider.img" alt="Vector image" />
                  <h2 class="swiper-header mb-1 mb-md-5">
                    {{ $t(slider.title) }}
                  </h2>
                  <p class="swiper-text">{{ $t(slider.content) }}</p>
                </div>
              </swiper-slide>
              <div class="swiper-pagination mt-10" slot="pagination"></div>
            </swiper>
          </div>
        </v-row>
      </v-container>
    </div>

    <div class="about">
      <v-container>
        <v-row align="center" justify="space-between">
          <v-col cols="12" md="4">
            <h2 class="about-header mb-1">
              {{ $t("home.exchangeYourThoughts.label") }}
            </h2>
            <p class="about-para">
              {{ $t("home.exchangeYourThoughts.content") }}
            </p>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="6" md="6">
            <v-row justify="end">
              <v-img
                max-height="428"
                max-width="640"
                width="100%"
                height="100%"
                src="../../assets/img/group-173.svg"
                alt="Feed"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/pagination/pagination.min.css";

import group1 from "../../assets/img/group-1.svg";
import group2 from "../../assets/img/group-2.svg";
import group7 from "../../assets/img/group-7.svg";
import group20 from "../../assets/img/group-20.svg";
import group21 from "../../assets/img/group-21.svg";
import group22 from "../../assets/img/group-22.svg";

SwiperCore.use([Pagination, Autoplay]);

export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: false,
        loop: true,
        speed: 800,
        autoplay: { delay: 3000 },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        breakpoints: {
          1900: {
            slidesPerView: 3,
          },
          1440: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
          0: {
            slidesPerView: 1,
          },
        },
      },
      sliders: [
        {
          title: "home.tellYourOpinion.label",
          content: "home.tellYourOpinion.content",
          img: group1,
        },
        {
          title: "home.seeWhatOthersThink.label",
          content: "home.seeWhatOthersThink.content",
          img: group7,
        },
        {
          title: "home.expressYourselfFreely.label",
          content: "home.expressYourselfFreely.content",
          img: group2,
        },
        {
          title: "home.privateEnvironment.label",
          content: "home.privateEnvironment.content",
          img: group20,
        },
        {
          title: "home.powerOfGroupChat.label",
          content: "home.powerOfGroupChat.content",
          img: group21,
        },
        {
          title: "home.freeAudioCalls.label",
          content: "home.freeAudioCalls.content",
          img: group22,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
